var Heros = {
    init: function(target) {
        $slider = $(target);
        $slider.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            speed: 500,
            pauseOnHover:false,
            pauseOnFocus:false,
            fade: true,
            cssEase: 'linear'
        });

      $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
          var current_class = $('header').attr('class');
          var  h_color= $('[data-slick-index=' + nextSlide + ']').data('header-color');
          
          $('header').addClass(h_color);
          $('header').removeClass(current_class);
        });

        $(target+' .scroll-down').click(function(e){
          $("html, body").animate({ scrollTop: $(target).innerHeight() }, 500); 
          e.preventDefault();
        });
    
    }
}