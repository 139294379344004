var brandNavigation = {
    init: function( ) {

    	$('.brands-items-sidebar-item').hover(function() {
	 		if(! $(this).hasClass('active') ){
	 			$('.brands-items-sidebar-item.active .img-wrapper').css('opacity', '0');
	 		}
	 	}, function() {
		    $('.brands-items-sidebar-item.active .img-wrapper').css('opacity', '1');
		  
	 	});
	 	var _height = $('.sidebar-nav').innerHeight() + 100;

	 	if( $('.sidebar-nav').innerHeight() > 400){
	 		$('.sidebar-nav').css('top', 'calc(100vh - 400px)');
	 	}else{
	 		$('.sidebar-nav').css('top', 'calc(100vh - '+_height+'px)');
	 	}
	 	


		var waypoints_down = $('.waypoint').waypoint(function(direction) {
			if(direction==='down'){
				var category = this.element.id;
				if(!$('.brands-items-sidebar-item').hasClass('active')){
					$('.brands-items-sidebar-item').removeClass('active');
					$('.brands-items-sidebar-item a[href="'+category+'"]').parent().addClass('active');
					$('.img-wrapper').attr('style','');
				}else{
					$('.brands-items-sidebar-item').removeClass('active');
					$('.brands-items-sidebar-item a[href="#'+category+'"]').parent().addClass('active');
					$('.img-wrapper').attr('style','');
				}
			}
			
			
		}, {
		  offset: '30%'
		});
		
		var waypoints_up = $('.waypoint').waypoint(function(direction) {
			if(direction==='up'){
				var category = this.element.id;
				if(!$('.brands-items-sidebar-item').hasClass('active')){
					$('.brands-items-sidebar-item').removeClass('active');
					$('.brands-items-sidebar-item a[href="#'+category+'"]').parent().addClass('active');
					$('.img-wrapper').attr('style','');
				}else{
					$('.brands-items-sidebar-item').removeClass('active');
					$('.brands-items-sidebar-item a[href="#'+category+'"]').parent().addClass('active');
					$('.img-wrapper').attr('style','');
				}
			}
			
		}, {
		  offset: '-20%'
		});
		
		
		$('.brands-items-sidebar-item a').click(function() {
			if(!$('.brands-items-sidebar-item').hasClass('active')){
				$('.brands-items-sidebar-item').removeClass('active');
				$(this).parent().addClass('active');
				$('.img-wrapper').attr('style','');
			}else{
				$('.brands-items-sidebar-item').removeClass('active');
				$(this).parent().addClass('active');
				$('.img-wrapper').attr('style','');
			}
		});
	}
}
var timeout = false;
var rtime;
var delta = 200;

$(function(){
   pinImage();
    setTimeout(function(){	
    	$(window).trigger('resize');
    },500);
 	
     $(window).resize(function(){
			var rtime = new Date();
			if (timeout === false) {
				timeout = true;
				resizedeltaFunction();
			}
		});
}); 

function resizedeltaFunction(){	
	if (new Date() - rtime < delta) {
        setTimeout(resizedeltaFunction, delta);
    } else {
        timeout = false;		
		pinImage();
    }	
}
function pinImage() {

	 if($('#pin-image').length>0) {
    	
           if($('.main-product-details-item').length>0){
	    		$pin_height =($('.main-product-details-item').offset().top - $('#product-details').offset().top) + $('.main-product-details-item').innerHeight();
	    		$('#pin-image').css('height',$pin_height);
	    	}else if($('.products-cta').length>0){
	    		$pin_height =($('.products-cta').offset().top - $('#product-details').offset().top) ;
	    		$('#pin-image').css('height',$pin_height);
	    	}else{
	    		$pin_height =($('.product-navigation').offset().top - $('#product-details').offset().top);
	    		$('#pin-image').css('height',$pin_height);
	    	}
    	
    }
}