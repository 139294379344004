var imgSlider = {
    init: function(target) {
        $slider = $(target);
        $slider.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear'        
        });

		$slider.parent().find('.arrow.left').click(function(){
		  $slider.slick('slickPrev');
		  return false;
		});

		$slider.parent().find('.arrow.right').click(function(){
		  $slider.slick('slickNext');
		  return false;
		});

    }
}

var videoPost = {
    init: function() {
       $('video, audio').mediaelementplayer({
            // Configuration
            success: function(media) {
                var isNative = /html5|native/i.test(media.rendererName);
         
                var isYoutube = ~media.rendererName.indexOf('youtube');
         
                // etc.
            }
        });

    }
}

