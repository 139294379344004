var values = [];

var Filters = {
	
	manual_filter_update:function($this , filter_name,filter_value){
		values[filter_name] = filter_value;
		filtersUpdate('#'+ $this.closest('.product-filters').data('controls') , values);
	},


    init:function(){
    	
    	$('.close-filter-sidebar').click(function(){
    		$('body').toggleClass('sidebar-opened');
    		$(this).closest('product-filters').toggleClass('filter-sidebar-open');
		});

    	$('.reset-filters').click(function(){
    		 window.history.pushState('','Title',window.location.href.split('?')[0]);
            var p = $(this).closest('.partial');
             p.data('page',1);

             p.data("category_id",'');
             
             p.data("subcategory_id",'');
             p.data("brand_id",'');
             p.data("attributes_is_genka",'');
             p.data("attributes_subcategory_id",'');
             p.data("attributes_color",'');
             p.data("attributes_country",'');
             p.data("attributes_type_id",'');
             p.data("",'');

             if(window.location.href.includes("wines")){
             	 p.data("category_id",'15');
             }
             load_partial(p);
             return false;
		});
    	
    	/*if($('.content-sidebar').length){
	        $('.content-sidebar, .content-main').theiaStickySidebar({
	          // Settings
	          additionalMarginTop: 100
	        });
	    }*/
		if ( $( '.product-filters' ).length > 0  && $( window ).width() < 992 ) {
           
            $( '#catalogue-builder' ).waypoint( function( direction ) {
                 if(direction==='down'){
                 	$( '.product-filters' ).addClass('product-filters-visible');
                 }else{
                 	$( '.product-filters' ).removeClass('product-filters-visible');
                 }
            }, {
                offset: '95%'
            } );

           
             $( '#catalogue-builder' ).waypoint( function( direction ) {
                 if(direction==='up'){
                 	$( '.product-filters' ).addClass('product-filters-visible');
                 }else{
                 	$( '.product-filters' ).removeClass('product-filters-visible');
                 }
            }, {
                offset: 'bottom-in-view'
            } );


        }
			
		$('.product-filters input[type="checkbox"] , .product-filters input[type="radio"]').change(function(){
			var options = [];
			values['page'] = '1';
			$(this).closest('.product-filters').find('.filter-group').each(function(){
				
				$(this).find('input[type="checkbox"]').each(function(){
                	var option_name = $(this).attr('name');
                	if(options.indexOf(option_name) == -1){
                		options.push(option_name);
                	}
					if(typeof(values[option_name]) == 'undefined' || values[option_name] == ''){
						values[option_name] = [];		
					}
					if($(this).is(':checked')){
						if(values[option_name].indexOf($(this).val()) == -1){
							values[option_name].push($(this).val());		
						}
					} else {
						if (Array.isArray(values[option_name])) {
							var val = $(this).val();
							values[option_name] = values[option_name].filter(function(v) { 
								return v != val;
							});
						}
					}
				});
	            $(this).find('input[type="radio"]').each(function(){
                	var option_name = $(this).attr('name');
                	if(options.indexOf(option_name) == -1){
                		options.push(option_name);
                	}
					if(typeof(values[option_name]) == 'undefined' || values[option_name] == ''){
						values[option_name] = [];		
					}
					if($(this).is(':checked')){
						if(values[option_name].indexOf($(this).val()) == -1){
							values[option_name].push($(this).val());		
						}
					} else {
						if (Array.isArray(values[option_name])) {
							var val = $(this).val();
							values[option_name] = values[option_name].filter(function(v) { 
								return v != val;
							});
						}
					}
	            });

			});
			for(var i in options){
				if(typeof(values[options[i]]) === 'undefined'){
					values[options[i]] = '';
				}
			}
            if($(this).closest('.product-filters').length == 0){
                alert('filters container not set');
                return false;
            }

            deepLinking(values);
            $(this).closest('.partial').data(values);
            
			filtersUpdate('#'+$(this).closest('.product-filters').data('controls'), values);
			load_partial($(this).closest('.partial'));

		});
		
		if($('.filter-group.slider').length>0){

            $('.filter-group.slider').each(function(){
				var counter = 0,
                    $this =  $(this),
                    min = $(this).data('min'),
                    max = $(this).data('max'),
                    filter_slider = $this.find('.slider-block').get(0),
                    filter_slider_name = $this.find('.slider-block').data('name');

                noUiSlider.create(filter_slider, {
					start: [ min, max ],
					step: 1,
					connect: true,
					behaviour: 'drag',
					range: {
						min: min,
						max: max
					},
					format: wNumb({
						decimals: 0,
						postfix: '$'
					})
				});
				var paddingMin = $('.slider-value-min span'),
					paddingMax = $('.slider-value-max span'),
                    sliderDelay = null;

				filter_slider.noUiSlider.on('update', function ( slider_values, handle ) {
					if ( handle ) {
						paddingMax.html(slider_values[handle]);
					} else {
						paddingMin.html(slider_values[handle]);
					}	
					counter = counter +1;


                    if(sliderDelay!=null){
                        clearTimeout(sliderDelay);
                        sliderDelay = null;
                    }

					if(counter>2){
						values[filter_slider_name] = slider_values[0].replace('$','')+','+slider_values[1].replace('$','');

                        sliderDelay = setTimeout(function(){
                        	values['page'] = '1';
                        	deepLinking(values);
                        	$(this).closest('.partial').data(values);
           					load_partial($(this).closest('.partial'));
                            filtersUpdate('#'+ $this.closest('.product-filters').data('controls') , values);
                        },500);
				    }
				});				
			});
		}
    },

    checkbox_search : function(container){

		var values = [];
		
		var content_scrollbar = container+'-content';
		content_scrollbar.replace("#", "");
		
		var el = new SimpleBar( 
			$(content_scrollbar)[0] ,
			{ autoHide: false }
		);
		
		$(container+' .show_all_btn').click(function(){
			$(this).closest('.show_all').hide();
			$(this).closest('.filter-content').find('.show_less').show();
			$(this).closest('.filter-content').find('.filter-content-list li.hidden-filter').removeClass('d-none');			
			el.recalculate();
			return false; 
		});

		$(container+' .show_less_btn').click(function(){
			$(this).closest('.show_less').hide();
			$(this).closest('.filter-content').find('.show_all').show();
			$(this).closest('.filter-content').find('.filter-content-list li.hidden-filter').addClass('d-none');			
			el.recalculate();
			scroll2element($(this).closest('.filter-content'));
			return false; 
		});


		$(container+' input:text').on('input', function(){
			var matches = 0;
			var $q = $(this).val().toLowerCase();
			if( $q ===''){
				$(container+' ul li:not(.show_all)').addClass('d-none');
				$(container+' ul li').each(function(){
					if (typeof $(this).data('index') !== "undefined" && $(this).data('index') < 9) {
                        $(this).removeClass('d-none');
                        
					}
				});
				$(container+' ul li.show_all').removeClass('d-none').show();
				el.recalculate();
			}else{

				$(container+' ul li:not(.show_all)').addClass('d-none');
				$(container+' ul li').each(function(){
					if(typeof $(this).data('search') !== "undefined" && String($(this).data('search')).toLowerCase().indexOf($q) >= 0) {
						$(this).removeClass('d-none');
						matches = matches +1;
						el.recalculate();
					}
				});	
				
				if(matches<9){
					$(container+' .simplebar-scrollbar').removeClass('visible');
				}else{
					$('.simplebar-scrollbar').addClass('visible');
					el.recalculate();
				}					
				
			}
		});					   
    }
};


function filtersUpdate(controls, values){
    var filters = [], 
    	t = $(controls); 
    	t.data(values); 
    
    load_partial( t , function(){
		scroll2element(controls);
    });
    
}

function deepLinking(request_data){
    //if(!$.isEmptyObject(request_data)){

	var _page= true;
    var h = '?';
    var del = '';
    for(var i in request_data){

    	if( i=='page' ){
    		_page = false;
    	}
    	if(request_data[i] == '' || i==='partial' || i==='current_url' || 
    								i==='widgetconfig' || i==='box_image_1' ||
    	 							i==='box_image_2' || i==='widgetConfig' ||  i==='content_info' ||
    	 							i==='page_position' || i==='images_content_title' || i==='content_title' || i==='content_subtitle' ) {
    		continue;
    	}



        h += del + i+'='+request_data[i];
        del = '&';
    }

     if(_page){
    	 h += '&page=1';
    }

    if(h!=getQueryString()){
        history.pushState({title:h}, '' , h);
    }


    //}
}

function getQueryString(){
    if(window.location.toString().indexOf('?')>0){
        return '?' + window.location.toString().split('?')[1];
    }else{
        return '';
    }
}