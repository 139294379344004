var numberRoller = {
    init: function( ) {
        if ( $( '.numscroller' ).length > 0  && 1===2) {
            $( '.numscroller' ).waypoint( function( direction ) {
                 if(direction==='down'){
                   numberRoller.start();
                 }
            }, {
                offset: '110%'
            } );

            $( '.numscroller' ).waypoint( function( direction ) {
               if(direction==='up'){
                 numberRoller.start();
               }
            }, {
                offset: '-100%'
            } );

        }

    },
    start: function( ) {
        var i = 1;
        $( '.numscroller' ).each( function( ) {
            $( this ).attr( 'data-slno', i );
            $( this ).addClass( "roller-title-number-" + i );
            var min = $( '.roller-title-number-' + i ).attr( 'data-min' );
            var max = $( '.roller-title-number-' + i ).attr( 'data-max' );
            var timediff = $( '.roller-title-number-' + i ).attr( 'data-delay' );
            var increment = $( '.roller-title-number-' + i ).attr( 'data-increment' );
            var numdiff = max - min;
            var timeout = ( timediff * 1000 ) / numdiff;
            //if(numinc<10){
            //increment=Math.floor((timediff*1000)/10);
            //}//alert(increment);
            numberRoller.roll( i, min, max, increment, timeout );
            i++;
        } );
    },
    roll: function( slno, min, max, increment, timeout ) {
        if ( min <= max ) {
            $( '.roller-title-number-' + slno ).html( min );
            min = parseInt( min ) + parseInt( increment );
            setTimeout( function( ) {
                numberRoller.roll( eval( slno ), eval( min ), eval( max ), eval( increment ), eval( timeout ) )
            }, timeout );
        } else {
            $( '.roller-title-number-' + slno ).html( max );
        }
    }
}